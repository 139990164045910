<template>
  <b-modal v-model="modalOpened" content-class="af-modal" hide-header hide-footer centered>
    <p class="text-uppercase font-weight-bold text-center mb-4">Delete Notification</p>
    <p class="text-center">Are you sure you want to delete this notification?</p>
    <div class="text-right">
      <b-button @click="deleteNotification" :disabled="deletingNotification" variant="danger" class="mt-4 px-4">
        <b-spinner small v-if="deletingNotification" label="Spinning"></b-spinner>
        <span v-else>Delete</span>
      </b-button>
      <b-button @click="modalOpened = false" :disabled="deletingNotification" variant="empty" class="mt-4 px-4">
        <span>Cancel</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { deleteNotification } from '../../apis/notifications'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      modalOpened: this.opened,
      deletingNotification: false
    }
  },
  watch: {
    opened (val) {
      this.modalOpened = val
    },
    modalOpened (val) {
      if (!val) {
        this.$emit('modal-closed')
      }
    }
  },
  methods: {
    async deleteNotification () {
      this.deletingNotification = true
      try {
        await deleteNotification(this.notification._id)
        this.$bvToast.toast('Notification deleted!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.modalOpened = false
        this.$emit('reload-notifications')
      } catch (error) {
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      } finally {
        this.notification = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
