<template>
  <div class="mb-5">
    <p>Email</p>
    <form @submit.prevent="onSubmit">
      <multiselect
        v-model="selectedUsers"
        :options="users"
        :multiple="true"
        :close-on-select="true"
        :searchable="true"
        :clear-on-select="false"
        :preserve-search="false"
        placeholder="Select users"
        label="name"
        track-by="email"
        :preselect-first="true"
        :disabled="selectAllUsers === 'true'"
      >
        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} users selected</span></template>
      </multiselect>
      <b-form-checkbox
        id="checkbox"
        v-model="selectAllUsers"
        value="true"
        unchecked-value="false"
        class="text-14"
      >
        select all users
      </b-form-checkbox>
      <b-form-input v-model="email.mailFrom" type="email" placeholder="From: example@airtimeflip.com" class="mt-4" required></b-form-input>
      <b-form-input v-model="email.subject" placeholder="Subject" class="mt-4" required></b-form-input>
      <b-form-textarea
        id="textarea"
        v-model="email.message"
        placeholder="Message"
        class="mt-4"
        rows="5"
        max-rows="5"
        required
      ></b-form-textarea>
      <b-button type="submit" variant="primary" class="mt-4">Send</b-button>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { sendEmail } from '../../apis/notifications'
import { getUsers } from '../../apis/users'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      users: [],
      selectedUsers: [],
      selectAllUsers: 'false',
      email: {
        mailFrom: '',
        mailTo: [],
        subject: '',
        message: ''
      }
    }
  },
  watch: {
    selectedUsers (val) {
      this.email.mailTo = val.map(item => item.email)
    },
    selectAllUsers (val) {
      if (val === 'true') {
        this.email.mailTo = this.users.map(item => item.email)
      } else {
        this.email.mailTo = []
      }
    }
  },
  async created () {
    try {
      const { data } = await getUsers()
      this.users = data.data
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    async onSubmit () {
      if (!this.email.mailTo.length) {
        return this.$bvToast.toast('Please select at least one user', {
          title: 'Notification',
          variant: 'warning',
          solid: true
        })
      }
      try {
        await sendEmail(this.email)
        this.$bvToast.toast('Emails sent', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.selectedUsers = []
        this.email = {
          mailFrom: '',
          mailTo: [],
          subject: '',
          message: ''
        }
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
