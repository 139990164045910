<template>
  <b-modal v-model="modalOpened" content-class="af-modal" size="lg" hide-header hide-footer centered>
    <p class="text-uppercase font-weight-bold text-center mb-4">Edit Notification</p>
    <div class="picker mb-4">
      <b-button variant="outline-primary" class="mr-2">Upload Image</b-button>
      <b-form-file v-model="notificationImage" accept="image/jpeg, image/png" class="file-picker"></b-form-file>
      <span v-if="notificationImage">{{ notificationImage.name }}</span>
    </div>
    <b-row>
      <b-col cols="5">
        <div class="image-preview">
          <p class="text-uppercase mb-0">Uploaded Image Preview</p>
          <img ref="previewImage">
        </div>
        <b-button v-if="clearPreview" @click="clearPreviewImage" variant="outline-primary" class="mt-2">Clear preview</b-button>
      </b-col>
      <b-col cols="7">
        <b-form-textarea
          id="textarea"
          v-model="message"
          placeholder="Enter additional note"
          rows="5"
          max-rows="5"
        ></b-form-textarea>
        <div class="text-right mt-4">
          <b-button @click="editNotification" :disabled="editingNotification" variant="primary">
            <b-spinner small v-if="editingNotification" label="Spinning"></b-spinner>
            <span v-else>Edit Notification</span>
          </b-button>
          <b-button @click="modalOpened = false" :disabled="editingNotification" variant="empty" class="px-4">
            <span>Cancel</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { editNotification } from '../../apis/notifications'
import { getFilePath } from '../../apis/users'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      modalOpened: this.opened,
      notificationImage: null,
      clearPreview: false,
      message: '',
      editingNotification: false
    }
  },
  watch: {
    opened (val) {
      this.modalOpened = val
    },
    modalOpened (val) {
      if (val) {
        this.message = this.notification.message
        this.getFilePath()
      }
      if (!val) {
        this.$emit('modal-closed')
      }
    },
    notificationImage (val) {
      if (val) {
        this.clearPreview = true
        const reader = new FileReader()
        reader.onload = (e) => {
          this.$refs.previewImage.src = e.target.result
        }
        reader.readAsDataURL(val)
      } else {
        this.$refs.previewImage.src = ''
      }
    }
  },
  methods: {
    async getFilePath () {
      try {
        const { data } = await getFilePath({ file_path: this.notification.imageUrl })
        this.$refs.previewImage.src = `data:image/png;base64, ${data.data}`
      } catch (error) {
        console.log(error)
      }
    },
    clearPreviewImage () {
      this.clearPreview = false
      this.notificationImage = null
    },
    async editNotification () {
      if (!this.message) {
        return this.$bvToast.toast('Please enter message', {
          title: 'Notification',
          variant: 'warning',
          solid: true
        })
      }

      const payload = new FormData()
      payload.append('file', this.notificationImage || '')
      payload.append('message', this.message)
      this.editingNotification = true
      try {
        await editNotification(payload, this.notification._id)
        this.$bvToast.toast('Notification edited!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.modalOpened = false
        this.$emit('reload-notifications')
      } catch (error) {
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      } finally {
        this.editingNotification = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
  position: relative;
  background: #C4C4C4;
  border-radius: 5px;
  width: 289px;
  height: 134px;
  padding: 55px 15px;
  text-align: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 289px;
    height: 134px;
    z-index: 10;
  }
}

.picker {
  position: relative;
}
.file-picker {
  opacity: 0;
  width: 132px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}
</style>
