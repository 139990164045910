<template>
  <div>
    <p>General</p>
    <div class="picker mb-4">
      <b-button variant="outline-primary" class="mr-2">Upload Image</b-button>
      <b-form-file v-model="notificationImage" accept="image/jpeg, image/png" class="file-picker"></b-form-file>
      <span v-if="notificationImage">{{ notificationImage.name }}</span>
    </div>
    <b-row>
      <b-col cols="5">
        <div class="image-preview">
          <p class="text-uppercase mb-0">Uploaded Image Preview</p>
          <img ref="previewImage">
        </div>
        <b-button v-if="clearPreview" @click="clearPreviewImage" variant="outline-primary" class="mt-2">Clear preview</b-button>
      </b-col>
      <b-col cols="7">
        <b-form-textarea
          id="textarea"
          v-model="message"
          placeholder="Enter additional note"
          rows="5"
          max-rows="5"
        ></b-form-textarea>
        <div class="text-right mt-4">
          <b-button @click="sendNotification" variant="primary">Send</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { sendNotification } from '../../apis/notifications'

export default {
  data () {
    return {
      notificationImage: null,
      clearPreview: false,
      message: ''
    }
  },
  watch: {
    notificationImage (val) {
      if (val) {
        this.clearPreview = true
        const reader = new FileReader()
        reader.onload = (e) => {
          this.$refs.previewImage.src = e.target.result
        }
        reader.readAsDataURL(val)
      } else {
        this.$refs.previewImage.src = ''
      }
    }
  },
  methods: {
    clearPreviewImage () {
      this.clearPreview = false
      this.notificationImage = null
    },
    async sendNotification () {
      if (!this.message) {
        return this.$bvToast.toast('Please enter message', {
          title: 'Notification',
          variant: 'warning',
          solid: true
        })
      }
      const payload = new FormData()
      payload.append('file', this.notificationImage || '')
      payload.append('message', this.message)

      try {
        await sendNotification(payload)
        this.$bvToast.toast('Notification sent', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.message = ''
        this.notificationImage = null
      } catch (error) {
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
  position: relative;
  background: #C4C4C4;
  border-radius: 5px;
  width: 289px;
  height: 134px;
  padding: 55px 15px;
  text-align: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 289px;
    height: 134px;
    z-index: 10;
  }
}

.picker {
  position: relative;
}
.file-picker {
  opacity: 0;
  width: 132px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}
</style>
