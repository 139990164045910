import { get, post, del, put } from './http'
import { NOTIFICATIONS, SEND_EMAIL } from '../utils/routes'

export const sendNotification = payload => post(NOTIFICATIONS, payload)

export const sendEmail = payload => post(SEND_EMAIL, payload)

export const getNotifications = () => get(NOTIFICATIONS)

export const deleteNotification = id => del(`${NOTIFICATIONS}/${id}`)

export const editNotification = (payload, id) => put(`${NOTIFICATIONS}/edit/${id}`, payload, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
