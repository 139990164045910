<template>
  <main class="container-fluid">
    <b-card class="af-card pb-5">
      <b-tabs content-class="mt-3">
        <b-tab title="All Notifications" title-link-class="px-0 mr-3" active>
          <ViewNotifications />
        </b-tab>
        <b-tab title="Notify Users" title-link-class="px-0 mr-3">
          <Notify />
        </b-tab>
        <b-tab title="Email Users" title-link-class="px-0 mr-3">
          <Email />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import ViewNotifications from '../components/notification/ViewNotifications.vue'
import Notify from '../components/notification/Notify.vue'
import Email from '../components/notification/Email.vue'

export default {
  metaInfo: {
    title: 'Notification',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    Notify,
    Email,
    ViewNotifications
  }
}
</script>

<style lang="scss" scoped>

</style>
