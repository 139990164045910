<template>
  <div>
    <b-table
      id="af-table"
      :items="notifications"
      :fields="fields"
      :filter="filter"
      :perPage="perPage"
      :currentPage="currentPage"
      select-mode="single"
      hover
      fixed
      responsive
      borderless
      selectable
      show-empty
    >
      <template #empty>
        <p class="text-center text-muted">No notifications found</p>
      </template>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          span="1"
        >
      </template>
      <template v-slot:head(type)>
        <span class="text-flow">Actions</span>
      </template>
      <template v-slot:head(createdAt)>
        <span class="text-flow">Date Sent</span>
      </template>
      <template v-slot:cell(message)="data">
        <span class="text-flow">{{ data.value }}</span>
      </template>
      <template v-slot:cell(createdAt)="data">
        <span class="text-flow">{{ data.value | format-date }}</span>
      </template>
      <template v-slot:cell(type)="data">
        <span @click="openEditModal(data.item)" class="icon-pencil edit mr-3"></span>
        <span @click="openDeleteModal(data.item)" @modal-closed="deleteOpened = false" class="del">x</span>
      </template>
    </b-table>

    <EditNotification
      @reload-notifications="getNotifications"
      @modal-closed="editOpened = false"
      :opened="editOpened"
      :notification="selectedNotification"
    />

    <DeleteNotification
      @reload-notifications="getNotifications"
      @modal-closed="deleteOpened = false"
      :opened="deleteOpened"
      :notification="selectedNotification"
    />
  </div>
</template>

<script>
import { getNotifications } from '../../apis/notifications'
import DeleteNotification from '../modals/DeleteNotification.vue'
import EditNotification from '../modals/EditNotification.vue'

export default {
  components: {
    DeleteNotification,
    EditNotification
  },
  data () {
    return {
      notifications: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      fields: ['message', 'createdAt', 'type'],
      deleteOpened: false,
      editOpened: false,
      selectedNotification: {}
    }
  },
  mounted () {
    this.getNotifications()
  },
  methods: {
    openEditModal (val) {
      this.editOpened = true
      this.selectedNotification = val
    },
    openDeleteModal (val) {
      this.deleteOpened = true
      this.selectedNotification = val
    },
    async getNotifications () {
      try {
        const { data } = await getNotifications()
        this.notifications = data.data
      } catch (error) {
        console.log(error.response.data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-flow {
  word-wrap: break-word;
}
.edit {
  color: #F38D04;
  cursor: pointer;
}
.del {
  background-color: #F38D04;
  color: white;
  padding: 3px 5px;
  cursor: pointer;
}
</style>
